var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardTemplate',[_c('Layout',{staticClass:"pd-y-3"},[_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('h3',{staticClass:"text-center"},[_vm._v("เปลี่ยนรหัสผ่าน")])]),_c('el-col',{attrs:{"span":24}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":"รหัสผ่านใหม่","prop":"password","rules":[
                { required: true, message: 'กรุณากรอกรหัสผ่านใหม่' },
                {
                  min: 8,
                  message: 'รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร',
                  trigger: 'blur',
                },
              ]}},[_c('el-input',{attrs:{"type":"password","show-password":""},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}})],1)],1),_c('div',{staticClass:"text-center mg-t-3"},[_c('el-button',{attrs:{"loading":_vm.btnLoading,"type":"primary"},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v("บันทึก")])],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }