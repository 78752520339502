<template>
  <DashboardTemplate>
    <Layout class="pd-y-3">
      <div>
        <el-row>
          <el-col :span="24">
            <h3 class="text-center">เปลี่ยนรหัสผ่าน</h3>
          </el-col>
          <el-col :span="24">
            <el-form :model="ruleForm" ref="ruleForm" label-position="top">
              <el-form-item
                label="รหัสผ่านใหม่"
                prop="password"
                :rules="[
                  { required: true, message: 'กรุณากรอกรหัสผ่านใหม่' },
                  {
                    min: 8,
                    message: 'รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="ruleForm.password"
                  type="password"
                  show-password
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="text-center mg-t-3">
              <el-button
                :loading="btnLoading"
                type="primary"
                @click="changePassword()"
                >บันทึก</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import Layout from "@/template/LayoutDefaultTemplate";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate,
    Layout
  },
  data() {
    return {
      sPhrase: '',
      ruleForm: {
        userId: this.$route.params.id,
        password: null
      },
      btnLoading: false
    };
  },
  mounted(){
    this.getSphase();
  },
  methods: {
    async getSphase(){
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.sphrase}`;
        let res = await HTTP.get(`key/sign`)
        if(res.data.success){
          this.sPhrase = res.data.data
        }
      } catch (error) {
        
      }
    },
    changePassword() {
      this.btnLoading = true;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let tempPassword = this.CryptoJS.AES.encrypt(
            this.ruleForm.password,
            this.sPhrase
          ).toString();
          let obj = {
            password: tempPassword,
            userId: this.$route.params.id
          };
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.put(`system/admin/change/password`, obj)
            .then(result => {
              if (result.data.success) {
                this.$message({
                  showClose: true,
                  message: "แก้ไขรหัสผ่านสำเร็จ",
                  type: "success"
                });
              }
            })
            .catch(e => {
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        } else {
          this.btnLoading = false;
        }
      });
    }
  }
};
</script>